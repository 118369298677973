import CampaignListLayout from '../component-library/layout/campaignListLayout/CampaignListLayout';
import SectionBackground from '../component-library/SectionBackground';
import { PageSectionComponent } from './pageSections';
import RichText from './RichText/RichText';
import Image, { ImageStyleProps } from './Image';
import getLinkHref from './util/getLinkHref';
import getSettingsFromSection from '../../utils/getSettingsFromSection';
import z from 'zod';
import { TypeCampaignItem } from '../../../@types/generated';
import { captureException } from '../../utils/tracking/sentry/sentry';

const settingsSchema = z.object({
  id: z.string().optional(),
  backgroundColor: z.string().optional(),
});

const CampaignsSection: PageSectionComponent<null> = ({ section }) => {
  const {
    fields: { title, description, references },
  } = section;
  const { data: settings, error } = settingsSchema.safeParse(getSettingsFromSection(section));
  if (error) captureException(error);

  return (
    <SectionBackground id={settings?.id} backgroundColor={settings?.backgroundColor}>
      <CampaignListLayout
        Title={({ color }) => <RichText document={title} textColor={color} />}
        Description={({ color }) => <RichText document={description} textColor={color} />}
        campaigns={(references as TypeCampaignItem[]).map(
          ({
            fields: {
              campaignName,
              campaignDescription,
              campaignLink,
              campaignCta,
              campaignImage,
              campaignPartnerLogo,
              campaignSubheader,
            },
          }) => ({
            name: campaignName,
            description: campaignDescription,
            link: getLinkHref(campaignLink),
            cta: campaignCta,
            subheader: campaignSubheader,
            // eslint-disable-next-line react/display-name
            Image: (props: ImageStyleProps) =>
              campaignImage ? <Image asset={campaignImage} {...props} /> : null,
            // eslint-disable-next-line react/display-name
            Logo: (props: ImageStyleProps) =>
              campaignPartnerLogo ? <Image asset={campaignPartnerLogo} {...props} /> : null,
          }),
        )}
      />
    </SectionBackground>
  );
};

export default CampaignsSection;
